.wrapperSignUpModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.wrapperTitle {
  padding: var(--indent-base);
}

.textTitle {
  font-size: var(--font-size-l);
  line-height: 20px;
  color: var(--sign-up-modal-bonus-text-title-color);
}

.subText {
  font-size: var(--font-size-s);
  color: var(--sign-up-modal-bonus-subtext-color);
  font-weight: 400;
  line-height: 18.2px;

  &::first-letter {
    text-transform: uppercase;
  }
}

.bonusText {
  font-size: var(--font-size-l);
  color: var(--sign-up-modal-bonus-text-color);
  padding: var(--indent-base);
  text-align: center;
  font-weight: 700;
  line-height: 26px;
}

.wrapperTabsAndForm {
  width: 100%;
}

.zIndex {
  position: relative;
  z-index: 3;
}

.tabs {
  margin-bottom: calc(var(--indent-base) * 2);
}

.marginB {
  margin-bottom: var(--indent-l);
}

.checkbox {
  margin-bottom: var(--indent-base);
  color: var(--sign-up-modal-checkbox-color);
}

.promoTitle {
  color: var(--promo-code-promo-title-color);
  font-size: var(--font-size-s);

  display: flex;
  align-items: center;
  gap: var(--indent-xs);

  .promocodeSvg {
    --size: 24px;
    width: var(--size);
    height: var(--size);
  }
}

.bigMargin {
  margin-bottom: 60px;
}

.wrapperReferralFailure {
  overflow-y: auto;
}

.authButton {
  height: 52px;
}

@media (min-width: 768px) {
  .wrapperReferralFailure {
    max-width: 360px;
    border-radius: var(--border-l);
  }
}
